<template>
  <footer class="main-footer sticky footer-type-1">
    <div class="footer-inner">
      <!-- 版权信息 -->
      <div class="footer-text">
        &copy; 2024 
        <a href="#"><strong>搜AI</strong></a> 
          <a href="https://sou-ai.cn" target="_blank"></a>
      </div>

      <div id="buttom" class="text-center">
        <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备2023010489号-2</a >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>

</style>
