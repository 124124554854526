<template>

    <div class="page-container">
      <div class="sidebar-menu toggle-others fixed">
        <div class="sidebar-menu-inner">
          <header class="logo-env">
            <!-- logo -->
            <div class="logo">
              <a href="javascript:void(0)" class="logo-expanded">
                <img src="../assets/images/logo@2x.png" width="100%" alt="" />
              </a>
              <a href="javascript:void(0)" class="logo-collapsed">
                <img src="../assets/images/logo-collapsed@2x.png" width="40" alt="" />
              </a>
            </div>
            
          </header>
  
          <!-- 侧边栏 -->
          <ul id="main-menu" class="main-menu">
            <li v-for="(menu, idx) in items" :key="idx">
              <a :href="'#' + transName(menu)" class="smooth">
                <i :class="menu.icon"></i>
                <span class="title">{{ transName(menu) }}</span>
              </a>
              <ul v-if="menu.children">
                <li v-for="(submenu, idx) in menu.children" :key="idx">
                  <a :href="'#' + transName(submenu)" class="smooth">
                    <span class="title">{{ transName(submenu) }}</span>
                    
                  </a>
                </li>
              </ul>
            </li>
           
          </ul>
        </div>
      </div>
  
  
  
      <div class="main-content">
        <nav class="navbar user-info-navbar" role="navigation">
          <ul class="user-info-menu left-links list-inline list-unstyled">
            <li class="hidden-sm hidden-xs">
              <a href="javascript:void(0)" data-toggle="sidebar"><i class="fa-bars"></i></a>
            </li>
            <li class="dropdown hover-line language-switcher">
              <a href="javascript:void(0)" class="dropdown-toggle" data-toggle="dropdown">
                <img :src="lang.flag" /> {{ lang.name }}
              </a>
              <ul class="dropdown-menu languages">
                <li :class="{ active: langItem.key === lang.key }" v-for="langItem in langList" :key="langItem.key">
                  <a href="javascript:void(0)" @click="lang = langItem">
                    <img :src="langItem.flag" /> {{ langItem.name }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
  
        </nav>
  
        <div class="s-search">
          <div id="search" class="s-search mx-auto">
            <div class="text-center mb-3">
              <img src="../assets/images/logo@2x.png" height="100px" class="logo-light" alt="AI工具集">
            </div>
            <br/>
            <form class="super-search-fm">
              <input type="text" id="search-text" v-model="inputText" class="form-control smart-tips search-key" zhannei=""
                placeholder="输入关键字搜索AI工具2322" style="outline:0" autocomplete="off" data-status="true">
              <button @click="searchResults" type="button" id="btn_search" ><i class="iconfont icon-search"></i></button>
            </form>
            <!-- <div id="search-list" class="hide-type-list">
              <div class="search-group justify-content-center group-a s-current">
                <ul class="search-type tab-auto-scrollbar overflow-x-auto">
                  <li><input checked="checked" hidden type="radio" name="type" data-page="home" id="type-zhannei"
                      value="https://sou-ai.cn/?post_type=sites&amp;s=%s%" data-placeholder="站内AI工具搜索">
                  </li>
  
                      
                </ul>
              </div>
              <br/>
             
            </div> -->
            
          </div>
        </div>
      
  
        <div v-for="(item, idx) in resultItems" :key="idx">
          <div v-if="item.web">
            <WebItem :item="item" :transName="transName" />
          </div>
        </div>
        
        <Footer />
      </div>
    </div>
  </template>
  
  <script>
  import WebItem from "../components/WebItem.vue";
  import Footer from "../components/Footer.vue";
  import itemsData from "../assets/data.json";
  import { loadJs } from '../assets/js/app.js'
  
  export default {
    name: "Index",
    components: {
      WebItem,
      Footer,
    },
    data() {
      return {
        inputText: '',
        resultItems: itemsData,
        lang: {},
        langList: [
          {
            key: "zh",
            name: "简体中文",
            flag: "./assets/images/flags/flag-cn.png",
          },
          // {
          //   key: "en",
          //   name: "English",
          //   flag: "./assets/images/flags/flag-us.png",
          // },
        ],
      };
    },
    created() {
      this.lang = this.langList[0];
      loadJs();
      this.inputText = this.$route.query.searchText;
      this.searchResults();
    },
    methods: {
      transName(webItem) {
        return this.lang.key === "en" ? webItem.en_name : webItem.name;
      },
      searchResults() {
        const query = this.inputText;
        console.log("====input: " + query);
        if (query) {
          fetch(`/api/data?q=${query}`)
            .then(response => response.json())
            .then(data => {
              console.log(data);
              this.resultItems = data.data;
            });
        }
      }
    },
  };
  </script>
  
  <style>
  </style>
  